import React from 'react';

const FriendBoldIC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={29}
			height={28}
			viewBox="0 0 29 28"
			fill="none"
		>
			<path
				d="M14.043 3.55078C11.4144 3.55078 9.27536 5.78845 9.27536 8.53945C9.27536 11.2403 11.2903 13.4278 13.9235 13.5164C13.9989 13.5094 14.0812 13.5083 14.1485 13.5164C14.1648 13.5176 14.181 13.5188 14.1972 13.5188H14.2135C16.7806 13.4289 18.8001 11.2426 18.8106 8.54062C18.8106 5.78961 16.6727 3.55195 14.043 3.55195V3.55078Z"
				fill="white"
			/>
			<path
				d="M19.1412 16.3198C16.3398 14.3633 11.767 14.361 8.94707 16.3198C8.94707 16.3198 8.94707 16.3198 8.94475 16.3198C7.67571 17.2088 6.97739 18.414 6.97971 19.7125C6.98203 21.0075 7.68035 22.2068 8.94591 23.0876C10.3518 24.0723 12.1974 24.5646 14.0441 24.5646C15.8908 24.5646 17.7364 24.0723 19.1423 23.0876C20.4114 22.1975 21.1097 20.9935 21.1074 19.695C21.105 18.4 20.4067 17.2006 19.1412 16.3198Z"
				fill="white"
			/>
			<path
				d="M25.0758 9.15949C25.2358 11.1977 23.8531 12.9792 21.931 13.2265L21.9113 13.2288L21.8823 13.2312C21.8231 13.2265 21.7651 13.2312 21.7083 13.2475C20.7362 13.2953 19.8407 12.9687 19.1667 12.3772C20.1991 11.4147 20.7896 9.96099 20.6678 8.38949C20.597 7.53782 20.3163 6.75732 19.8906 6.10166C20.2745 5.90332 20.7118 5.77149 21.1677 5.72949C23.1536 5.54749 24.9018 7.08282 25.0758 9.16066V9.15949Z"
				fill="white"
			/>
			<path
				d="M27.0907 18.877C27.006 19.892 26.3877 20.774 25.3402 21.3749C24.3345 21.9582 23.0724 22.2219 21.815 22.1915C22.5377 21.509 22.9611 20.662 23.0365 19.7509C23.1351 18.4524 22.5446 17.1959 21.358 16.2007C20.6886 15.6442 19.8998 15.202 19.0496 14.8824C21.2721 14.2104 24.0561 14.6654 25.7799 16.1109C26.7021 16.8914 27.1742 17.8725 27.0907 18.877Z"
				fill="white"
			/>
			<path
				d="M3.001 9.15949C2.84092 11.1977 4.22364 12.9792 6.14576 13.2265L6.16548 13.2288L6.19448 13.2312C6.25364 13.2265 6.31164 13.2312 6.36848 13.2475C7.34056 13.2953 8.23608 12.9687 8.91004 12.3772C7.87764 11.4147 7.2872 9.96099 7.409 8.38949C7.47976 7.53782 7.76048 6.75732 8.1862 6.10166C7.80224 5.90332 7.36492 5.77149 6.90904 5.72949C4.92312 5.54749 3.175 7.08282 3.001 9.16066V9.15949Z"
				fill="white"
			/>
			<path
				d="M0.987244 18.877C1.07192 19.892 1.6902 20.774 2.73768 21.3749C3.7434 21.9582 5.00548 22.2219 6.26292 22.1915C5.54024 21.509 5.11684 20.662 5.04144 19.7509C4.94284 18.4524 5.53328 17.1959 6.71996 16.2007C7.38928 15.6442 8.17808 15.202 9.02836 14.8824C6.8058 14.2104 4.0218 14.6654 2.29804 16.1109C1.37584 16.8914 0.903724 17.8725 0.987244 18.877Z"
				fill="white"
			/>
		</svg>
	);
};

export default FriendBoldIC;
