import React from 'react';

const CloseOutlineIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="none"
		>
			<path
				d="M12.3308 11L18.724 4.60677C19.092 4.23878 19.092 3.64398 18.724 3.27599C18.356 2.908 17.7612 2.908 17.3932 3.27599L11 9.66921L4.60677 3.27599C4.23878 2.908 3.64398 2.908 3.27599 3.27599C2.908 3.64398 2.908 4.23878 3.27599 4.60677L9.66921 11L3.27599 17.3932C2.908 17.7612 2.908 18.356 3.27599 18.724C3.36328 18.8115 3.46699 18.8809 3.58118 18.9282C3.69537 18.9755 3.81778 18.9998 3.94138 18.9997C4.18231 18.9997 4.42324 18.9075 4.60677 18.724L11 12.3308L17.3932 18.724C17.4805 18.8115 17.5842 18.8809 17.6984 18.9282C17.8126 18.9755 17.935 18.9998 18.0586 18.9997C18.2995 18.9997 18.5405 18.9075 18.724 18.724C19.092 18.356 19.092 17.7612 18.724 17.3932L12.3308 11Z"
				fill="white"
			/>
		</svg>
	);
};

export default CloseOutlineIc;
