import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RootState, useAppSelector } from '../../redux/store';
import { ROUTES } from '../../routes';
import { useEffect, useRef } from 'react';

const Layer = () => {
	const location = useLocation();

	return (
		<Wrapper>
			{location.pathname === ROUTES.HOME && <LoadingFarm />}
			<LayerBg />
		</Wrapper>
	);
};

const LoadingFarm = () => {
	const { loading } = useAppSelector((state: RootState) => state.farm);
	const videoRef = useRef<any>(null);
	// useEffect(() => {
	// 	const videoElement = document.getElementById('farm-loading');
	// 	videoElement?.addEventListener('suspend', () => {
	// 		console.log('suspend');
	// 		setRun(false);
	// 		// suspend invoked
	// 		// show play button
	// 		// iphone is in low power mode
	// 	});

	// 	videoElement?.addEventListener('play', () => {
	// 		console.log('play');
	// 		setRun(true);
	// 		// video is played
	// 		// remove play button UI
	// 		// iphone is not in low power mode
	// 	});
	// }, []);

	useEffect(() => {
		if (loading) {
			videoRef.current?.play();
		}
	}, [loading]);

	return (
		<WrapperLoading>
			<StyledBlurTop />
			{/* <StyledVideoLoading
				dangerouslySetInnerHTML={{
					__html: `<video loop muted autoplay playsinline src="/video/farm.m4v"/>`,
				}}
			/> */}
			<LoadingWrapper>
				<LoadingVideo
					loop
					muted
					autoPlay
					playsInline
					hidden={!loading}
					ref={videoRef}
				>
					<source src="/video/farm.m4v" type="video/mp4" />
				</LoadingVideo>

				<LoadingImage
					src="/images/home/padton-logo.png"
					alt="padton logo"
					hidden={loading}
				/>
			</LoadingWrapper>

			<StyledBlurBottom />
		</WrapperLoading>
	);
};

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const WrapperLoading = styled.div`
	position: relative;
	transform: scale(1.5);
	left: 10px;
`;

const StyledVideoLoading = styled.div`
	text-align: center;
	width: 100%;
	video {
		width: 100%;
	}
`;

const LoadingWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 270px;
`;

const LoadingVideo = styled.video<{ hidden: boolean }>`
	/* position: relative; */
	width: 100%;
	text-align: center;
	display: ${(props) => (props.hidden ? 'none' : 'block')};
`;

const LoadingImage = styled.img`
	/* position: absolute;
	z-index: 2; */
	width: 216px;
	display: ${(props) => (props.hidden ? 'none' : 'block')};
	transform: scale(0.5);
	/* height: 100%; */
`;

const StyledBlurTop = styled.div`
	position: absolute;
	top: 0;
	height: 68px;
	width: 100%;
	z-index: 2;
	background: linear-gradient(180deg, #000 48.77%, rgba(0, 0, 0, 0) 100%);
`;

const StyledBlurBottom = styled.div`
	position: absolute;
	bottom: 0;
	height: 68px;
	width: 100%;
	z-index: 2;
	transform: rotate(180deg);
	background: linear-gradient(180deg, #000 48.77%, rgba(0, 0, 0, 0) 100%);
`;

const LayerBg = styled.div`
	background-image: url('/images/home/bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

export default Layer;
