import { Flex } from 'antd';
import CopyIC from '../Icons/CopyIC';
import FriendBoldIC from '../Icons/FriendBoldIC';
import {
	StyledButtonInvite,
	StyledErnInvite,
	StyledItem,
	StyledItemLabel,
	StyledItemValue,
	StyledLayout,
	StyledTitle,
	Wrapper,
} from './index.styled';
import { Profile } from '../../redux/reducers/referral.slice';
import { onCopy } from '../../utils/copy';

interface IProps {
	summary?: Summary;
	profile: Profile | null;
	onInvite: () => void;
}

const Referral = ({ summary, profile, onInvite }: IProps) => {
	return (
		<Wrapper>
			<StyledTitle>Referral</StyledTitle>
			<StyledLayout>
				<StyledItem>
					<StyledItemLabel>Total Earned</StyledItemLabel>
					<StyledItemValue>
						<div>{summary?.totalEarn || '--'}</div>
						<div>
							<img src="/images/referral/padton-coin.svg" alt="padton coin" />
						</div>
					</StyledItemValue>
				</StyledItem>
				<StyledItem>
					<StyledItemLabel>Total Friends</StyledItemLabel>
					<StyledItemValue>
						<div>
							<FriendBoldIC />
						</div>
						<div>{summary?.totalNetwork || '--'}</div>
					</StyledItemValue>
				</StyledItem>
				<StyledItem span={2}>
					<Flex justify="space-between" align="center">
						<StyledItemLabel>Referral Code:</StyledItemLabel>
						<StyledItemValue fontSize="16px">
							<div>{profile?.code}</div>
							<div
								onClick={() => {
									onCopy(profile?.code || '');
								}}
							>
								<CopyIC />
							</div>
						</StyledItemValue>
					</Flex>
				</StyledItem>
				<StyledItem span={2}>
					<StyledErnInvite>
						<StyledItemValue style={{ lineHeight: '34px' }}>
							EARN 15000 PATC
						</StyledItemValue>
						<StyledItemLabel>
							For EACH NEW FRIEND YOU INVITE TO PadTON!
						</StyledItemLabel>

						<img
							src="/images/referral/bg-invite-friend.png"
							alt="invite friend"
						/>

						<StyledButtonInvite onClick={onInvite}>
							Invite Friends
						</StyledButtonInvite>
					</StyledErnInvite>
				</StyledItem>
			</StyledLayout>
		</Wrapper>
	);
};

export default Referral;
