import { Typography } from 'antd';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { MISSION_CODE } from '../../constants/mission';
import { IMission } from '../../pages/mission';
import { MissionRequest } from '../../service/mission.request';
import TaskItemComponent from './TaskItem';

interface IProps {
	list: IMission[];
	reFetchMissions: () => void;
}

export default function TaskList({ list, reFetchMissions }: IProps) {
	const claimReward = async (IMission: IMission) => {
		try {
			toast.loading('Claiming...');
			const req = new MissionRequest();
			const res = await req.claim(IMission.id);
			toast.dismiss();
			toast.success('Claim successful');
			if (res.data?.data) {
				reFetchMissions();
			}
		} catch (error: any) {
			console.log(error);
			const errStatus = error?.response?.status;
			if (
				(IMission.code === MISSION_CODE.JOIN_TELE_CHANNEL ||
					IMission.code === MISSION_CODE.JOIN_TELE_COMMUNITY) &&
				errStatus &&
				errStatus === 400
			) {
				reFetchMissions();
			}
			toast.dismiss();
			const message = error?.response?.data?.message || '';
			toast.error(message);
		}
	};

	const startMission = async (missionId: string) => {
		try {
			const req = new MissionRequest();
			const res = await req.start(missionId);
			if (res.data?.data) {
				reFetchMissions();
			}
			const mission = list.find((item) => item.id === missionId);
			if (mission && mission.info.url) {
				const tg = window.Telegram.WebApp;
				if (
					(
						[
							MISSION_CODE.JOIN_TELE_CHANNEL,
							MISSION_CODE.JOIN_TELE_COMMUNITY,
						] as string[]
					)?.includes(mission.code)
				) {
					tg?.openTelegramLink(mission.info?.url);
				} else {
					tg?.openLink(mission.info?.url);
				}
			}
		} catch (error: any) {
			console.log(error);
			const message = error?.response?.data?.message || '';
			toast.error(message);
		}
	};

	return (
		<Wrapper>
			<Title>Task List</Title>
			<CardsWrapper>
				{list
					.sort((prevTask, nextTask) => prevTask.priority - nextTask.priority)
					.map((task) => (
						<TaskItemComponent
							key={task.id}
							task={task}
							claimReward={claimReward}
							startMission={startMission}
						/>
					))}
			</CardsWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const Title = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
const CardsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;
