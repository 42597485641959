import styled from 'styled-components';
import PageTitle from '../UI/PageTitle';

interface IProps {
	onBack: () => void;
}

export default function PackageHeader({ onBack }: IProps) {
	return (
		<Wrapper>
			<BackBtn onClick={onBack}>
				<img src="/images/packages/back-btn.svg" alt="back-btn" />
			</BackBtn>
			<PageTitle content="Packages" />
			<div />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const BackBtn = styled.div`
	cursor: pointer;
`;
