import { Button, Typography } from 'antd';
import styled from 'styled-components';
import PopupUI from '../UI/Popup/PopupUI';
import { getPackageImage } from './Item';

export interface IPackage {
	id: string;
	description: string;
	name: string;
	price: string;
	growthLoyaltyRate?: number;
	loyaltyBonus?: string;
	loyaltyTokenSymbol: string;
	reducedPeriodPercent: number;
	specialBonusCondition?: string;
	specialLoyaltyBonus?: string;
	validityPeriod: number;
}

interface IProps {
	packageInfo?: IPackage;
	open: boolean;
	onClose: () => void;
	onBuy: (id: string) => void;
}

export default function UpgradeBoosterPopup({
	packageInfo,
	open,
	onClose,
	onBuy,
}: IProps) {
	return (
		<PopupUI
			open={open}
			onClose={onClose}
			footer={
				<BuyButton
					onClick={() => {
						onBuy(packageInfo?.id || '');
					}}
					disabled={!packageInfo}
				>
					<TextButton>Buy Now</TextButton>
				</BuyButton>
			}
			title="Booster"
		>
			<ContentWrapper>
				<PackageName>{packageInfo?.name}</PackageName>
				<PackageImgWrapper>
					<PackageImg
						src={getPackageImage(packageInfo?.name || '')}
						alt={packageInfo?.name}
					/>
				</PackageImgWrapper>

				<div>
					<PackageDescription>{packageInfo?.description}</PackageDescription>
					<PriceWrapper>
						<TokenImg src="/images/tokens/patc.svg" alt="patc" />
						<Price>
							{packageInfo?.price} {packageInfo?.loyaltyTokenSymbol}
						</Price>
					</PriceWrapper>
				</div>
			</ContentWrapper>
		</PopupUI>
	);
}

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
`;

const BuyButton = styled(Button)`
	height: max-content;
	padding: 12px 20px;
	width: 100%;
`;

const TextButton = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const PackageName = styled(Typography)`
	text-align: center;

	/* App/Title/Semibold */
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
`;

const PackageDescription = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 21px; /* 131.25% */
`;

const PriceWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
`;

const Price = styled(Typography)`
	color: #f5d245;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const TokenImg = styled.img`
	width: 32px;
	height: 32px;
`;

const PackageImgWrapper = styled.div`
	display: flex;
	padding: 18.42px 57.895px 15.791px 55.263px;
	justify-content: center;
	align-items: center;
	border-radius: 15.789px;
	border: 2.632px solid #273662;
	background: #000;
`;

const PackageImg = styled.img`
	height: 165.789px;
`;
