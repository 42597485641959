import React from 'react';

export default function MissionIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M13.3 8.10961L14.62 10.7496C14.8 11.1096 15.28 11.4696 15.68 11.5296L18.07 11.9296C19.6 12.1896 19.96 13.2896 18.86 14.3896L17 16.2496C16.69 16.5596 16.51 17.1696 16.61 17.6096L17.14 19.9196C17.56 21.7396 16.59 22.4496 14.98 21.4996L12.74 20.1696C12.33 19.9296 11.67 19.9296 11.26 20.1696L9.01996 21.4996C7.40996 22.4496 6.43995 21.7396 6.85995 19.9196L7.38998 17.6096C7.48998 17.1796 7.30997 16.5696 6.99997 16.2496L5.13998 14.3896C4.03998 13.2896 4.39996 12.1796 5.92996 11.9296L8.31997 11.5296C8.71997 11.4596 9.19997 11.1096 9.37997 10.7496L10.7 8.10961C11.41 6.67961 12.59 6.67961 13.3 8.10961Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M6 9V2"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M18 9V2"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M12 4V2"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
