import React from 'react';

export default function FriendActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M23.3734 10.3593C23.2801 10.346 23.1867 10.346 23.0934 10.3593C21.0267 10.2927 19.3867 8.59935 19.3867 6.51935C19.3867 4.39935 21.1067 2.66602 23.2401 2.66602C25.3601 2.66602 27.0934 4.38602 27.0934 6.51935C27.0801 8.59935 25.4401 10.2927 23.3734 10.3593Z"
				fill="white"
			/>
			<path
				d="M27.72 19.6005C26.2266 20.6005 24.1333 20.9739 22.2 20.7205C22.7066 19.6272 22.9733 18.4139 22.9866 17.1339C22.9866 15.8005 22.6933 14.5339 22.1333 13.4272C24.1066 13.1605 26.2 13.5339 27.7066 14.5339C29.8133 15.9205 29.8133 18.2005 27.72 19.6005Z"
				fill="white"
			/>
			<path
				d="M8.58655 10.3593C8.67988 10.346 8.77322 10.346 8.86655 10.3593C10.9332 10.2927 12.5732 8.59935 12.5732 6.51935C12.5732 4.38602 10.8532 2.66602 8.71988 2.66602C6.59988 2.66602 4.87988 4.38602 4.87988 6.51935C4.87988 8.59935 6.51988 10.2927 8.58655 10.3593Z"
				fill="white"
			/>
			<path
				d="M8.73349 17.1341C8.73349 18.4275 9.01349 19.6541 9.52016 20.7608C7.64016 20.9608 5.68016 20.5608 4.24016 19.6141C2.13349 18.2141 2.13349 15.9341 4.24016 14.5341C5.66682 13.5741 7.68016 13.1875 9.57349 13.4008C9.02682 14.5208 8.73349 15.7875 8.73349 17.1341Z"
				fill="white"
			/>
			<path
				d="M16.1599 21.16C16.0532 21.1467 15.9332 21.1467 15.8132 21.16C13.3599 21.08 11.3999 19.0667 11.3999 16.5867C11.4132 14.0533 13.4532 12 15.9999 12C18.5332 12 20.5866 14.0533 20.5866 16.5867C20.5732 19.0667 18.6266 21.08 16.1599 21.16Z"
				fill="white"
			/>
			<path
				d="M11.8269 23.9195C9.81356 25.2662 9.81356 27.4795 11.8269 28.8129C14.1202 30.3462 17.8802 30.3462 20.1736 28.8129C22.1869 27.4662 22.1869 25.2529 20.1736 23.9195C17.8936 22.3862 14.1336 22.3862 11.8269 23.9195Z"
				fill="white"
			/>
		</svg>
	);
}
