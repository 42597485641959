import React from 'react';

export default function DiamondIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={28}
			height={28}
			viewBox="0 0 28 28"
			fill="none"
		>
			<path
				d="M11.9036 9.01731L13.5026 12.7754L13.7673 10.4318L13.7708 10.4322C13.7759 10.3756 13.7923 10.3205 13.8189 10.2703C13.8566 10.2022 13.9119 10.1456 13.9791 10.1063L13.5201 6.041L11.9036 9.01731ZM9.20508 7.3325L11.2399 8.40569L13.0888 5.00019L10.6331 4.83044L9.20508 7.3325ZM7.61564 6.6395L3.45458 10.9856L2.56033 12.5523L4.08633 11.5911L8.24783 7.24456L9.14164 5.67787L7.61564 6.6395ZM13.4396 21.1321L16.0966 14.8877L14.4805 11.9114L13.4396 21.1321ZM22.6236 20.4002L25.946 22.3956L26.2221 19.9496L23.7843 18.4144L22.6236 20.4002ZM14.9114 10.8706L16.7603 14.2761L18.7951 13.2029L17.3671 10.7008L14.9114 10.8706ZM20.3845 12.5099L18.8581 11.5487L19.7523 13.1154L23.9138 17.4615L25.4403 18.4227L24.5456 16.856L20.3845 12.5099ZM4.21583 12.5436L1.77808 14.0792L2.05414 16.5248L5.37651 14.5298L4.21583 12.5436ZM15.6289 23.4198L24.8873 22.7806L21.9836 21.0368L15.6289 23.4198ZM6.74676 14.5057L13.0406 16.8656L13.3031 14.5425L11.2753 9.77594L6.74676 14.5057ZM19.3113 13.9195L17.2993 14.9809L21.9429 19.831L23.0909 17.8671L19.3113 13.9195ZM16.7248 15.6463L13.4711 23.2943L21.2534 20.3761L16.7248 15.6463ZM10.7009 9.1105L8.68883 8.04913L4.90926 11.9967L6.05726 13.9606L10.7009 9.1105ZM12.3708 17.549L6.01614 15.1664L3.11289 16.9098L12.3708 17.549ZM18.3751 4.8125V5.6875H17.5001V6.5625H18.3751V7.4375H19.2501V6.5625H20.1251V5.6875H19.2501V4.8125H18.3751ZM19.6876 8.3125H20.5626V9.1875H19.6876V8.3125ZM4.37508 20.5625V21.4375H3.50008V22.3125H4.37508V23.1875H5.25008V22.3125H6.12508V21.4375H5.25008V20.5625H4.37508ZM5.68758 18.8125H6.56258V19.6875H5.68758V18.8125ZM24.9376 10.9375H25.8126V11.8125H24.9376V10.9375Z"
				fill="white"
			/>
		</svg>
	);
}
