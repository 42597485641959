import React from 'react';

const CopyOutline = ({ onClick }: { onClick?: any }) => {
	return (
		<svg
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={20}
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M5 18.125H12.5C12.9973 18.125 13.4742 17.9275 13.8258 17.5758C14.1775 17.2242 14.375 16.7473 14.375 16.25V6.25C14.375 5.75272 14.1775 5.27581 13.8258 4.92417C13.4742 4.57254 12.9973 4.375 12.5 4.375H5C4.50272 4.375 4.02581 4.57254 3.67417 4.92417C3.32254 5.27581 3.125 5.75272 3.125 6.25V16.25C3.125 16.7473 3.32254 17.2242 3.67417 17.5758C4.02581 17.9275 4.50272 18.125 5 18.125ZM12.5 5.625C12.6658 5.625 12.8247 5.69085 12.9419 5.80806C13.0592 5.92527 13.125 6.08424 13.125 6.25V16.25C13.125 16.4158 13.0592 16.5747 12.9419 16.6919C12.8247 16.8092 12.6658 16.875 12.5 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V6.25C4.375 6.08424 4.44085 5.92527 4.55806 5.80806C4.67527 5.69085 4.83424 5.625 5 5.625H12.5Z"
				fill="white"
			/>
			<path
				d="M16.875 11.25C17.0408 11.25 17.1997 11.1842 17.3169 11.0669C17.4342 10.9497 17.5 10.7908 17.5 10.625V3.125C17.5 2.62772 17.3025 2.15081 16.9508 1.79917C16.5992 1.44754 16.1223 1.25 15.625 1.25H9.375C9.20924 1.25 9.05027 1.31585 8.93306 1.43306C8.81585 1.55027 8.75 1.70924 8.75 1.875C8.75 2.04076 8.81585 2.19973 8.93306 2.31694C9.05027 2.43415 9.20924 2.5 9.375 2.5H15.625C15.7908 2.5 15.9497 2.56585 16.0669 2.68306C16.1842 2.80027 16.25 2.95924 16.25 3.125V10.625C16.25 10.7908 16.3158 10.9497 16.4331 11.0669C16.5503 11.1842 16.7092 11.25 16.875 11.25Z"
				fill="white"
			/>
		</svg>
	);
};

export default CopyOutline;
