export enum MISSION_CODE {
	DAILY_ATTENDANCE = 'DAILY_ATTENDANCE',
	DAILY_SHARING_STORY = 'DAILY_SHARING_STORY',
	CONNECT_WALLET = 'CONNECT_WALLET',
	JOIN_TELE_CHANNEL = 'JOIN_TELE_CHANNEL',
	JOIN_TELE_COMMUNITY = 'JOIN_TELE_COMMUNITY',
	FOLLOW_X = 'FOLLOW_X',
	FOLLOW_COINMARKETCAP_COMMUNITY = 'FOLLOW_COINMARKETCAP_COMMUNITY',
	FOLLOW_BINANCE_SQUARE = 'FOLLOW_BINANCE_SQUARE',
	VISIT_WEBSITE = 'VISIT_WEBSITE',
	VISIT_HACKATHON_EVENT = 'VISIT_HACKATHON_EVENT',
	VISIT_MASCOT_CONTEST = 'VISIT_MASCOT_CONTEST',
	INVITE_1_FRIEND = 'INVITE_1_FRIEND',
	INVITE_3_FRIENDS = 'INVITE_3_FRIENDS',
	INVITE_10_FRIENDS = 'INVITE_10_FRIENDS',
	INVITE_100_FRIENDS = 'INVITE_100_FRIENDS',
	INVITE_1000_FRIENDS = 'INVITE_1000_FRIENDS',
	TOP_1_EARNER_WEEK = 'TOP_1_EARNER_WEEK',
	TOP_3_EARNERS_WEEK = 'TOP_3_EARNERS_WEEK',
	TOP_10_EARNERS_WEEK = 'TOP_10_EARNERS_WEEK',
	TOP_100_EARNERS_WEEK = 'TOP_100_EARNERS_WEEK',
	TOP_1000_EARNERS_WEEK = 'TOP_1000_EARNERS_WEEK',
	TOP_1000_INVITERS_WEEK = 'TOP_1000_INVITERS_WEEK',
}

export enum MISSION_TYPE {
	DAILY = 'DAILY',
	WEEKLY = 'WEEKLY',
	ONCE = 'ONCE',
}

export enum MISSION_SOURCE_TYPE {
	DECENTRALIZED_WALLET = 'DECENTRALIZED_WALLET',
	TELEGRAM = 'TELEGRAM',
	WEBSITE = 'WEBSITE',
	HACKATHON = 'HACKATHON',
	MASCOT = 'MASCOT',
	REFERRAL = 'REFERRAL',
	RANKING = 'RANKING',
	X = 'X',
	COINMARKETCAP = 'COINMARKETCAP',
	BINANCE = 'BINANCE',
}

export enum MISSION_STATUS {
	INIT = 'INIT',
	PENDING = 'PENDING',
	SUCCESS = 'SUCCESS',
	FAILED = 'FAILED',
}
