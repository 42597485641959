import React, { useEffect, useRef, useState } from 'react';
import {
	Container,
	StyledTextLoading,
	StyledTitlePage,
	Wrapper,
} from './index.styled';
import TopRanking from './TopRanking';
import ListRanking from './ListRanking';
import ReferralRequest from '../../service/referral.request';

const Ranking = () => {
	const [loading, setLoading] = useState(true);
	const referralRequest = new ReferralRequest();
	const [inviters, setInviters] = useState<Inviter[]>([]);
	const listInnerRef = useRef<HTMLDivElement>(null);
	const [currPage, setCurrPage] = useState(1);
	const [prevPage, setPrevPage] = useState(0);
	const [lastList, setLastList] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const response = await referralRequest.getInviters({
					page: currPage,
					pageSize: 10,
				});
				if (!response.data.data?.length) {
					setLastList(true);
					return;
				}
				setPrevPage(currPage);
				const finalData = [...inviters, ...response.data.data]
					.map((item) => {
						return {
							...item,
							username: `${item.username.slice(0, 6)}***`,
						};
					})
					.sort((a, b) => b.totalFriends - a.totalFriends);
				setInviters(finalData);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		if (!lastList && prevPage !== currPage) {
			fetchData();
		}
	}, [currPage, lastList, prevPage, inviters]);

	const onScroll = () => {
		if (listInnerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
			if (Math.round(scrollTop) + clientHeight === scrollHeight) {
				setCurrPage(currPage + 1);
			}
		}
	};

	return (
		<Wrapper ref={listInnerRef} onScroll={onScroll}>
			<Container>
				<StyledTitlePage>Ranking</StyledTitlePage>
				{(!loading || currPage > 1) && (
					<>
						<TopRanking list={inviters.slice(0, 3)} />
						<ListRanking list={inviters.slice(3, inviters.length)} />
					</>
				)}
				{currPage > 1 && loading && (
					<StyledTextLoading>Loading...</StyledTextLoading>
				)}
			</Container>
		</Wrapper>
	);
};

export default Ranking;
