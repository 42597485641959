import { useEffect } from 'react';
import styled from 'styled-components';
import Balance from '../components/Home/Balance';
import BoosterCowndown from '../components/Home/BoosterCowndown';
import FarmPool from '../components/Home/FarmPool';
import HomeHeader from '../components/Home/Header';
import { fetchMyBoosters } from '../redux/reducers/booster.slice';
import { fetchBalance } from '../redux/reducers/loyalty-wallet.slice';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

export default function HomePage() {
	const dispatch = useAppDispatch();
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const { myBoosters } = useAppSelector((state: RootState) => state.booster);

	useEffect(() => {
		dispatch(fetchMyBoosters());
		dispatch(fetchBalance());
	}, []);

	return (
		<Wrapper>
			<HomeHeader />
			{!!myBoosters?.length && <BoosterCowndown boosters={myBoosters} />}
			<Balance balance={balance} />
			<FarmPool />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	/* height: calc(100vh - 84px); */
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
`;
