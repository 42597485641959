import React from 'react';

export default function ConnectWalletIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={20}
			height={18}
			viewBox="0 0 20 18"
			fill="none"
		>
			<path
				d="M19.871 8.01045V9.98805C19.871 10.516 19.4486 10.948 18.911 10.9672H17.0294C15.9926 10.9672 15.0422 10.2089 14.9558 9.17205C14.8982 8.56725 15.1286 8.00085 15.5318 7.60725C15.887 7.24245 16.3766 7.03125 16.9142 7.03125H18.911C19.4486 7.05045 19.871 7.48245 19.871 8.01045Z"
				fill="white"
			/>
			<path
				d="M18.4021 12.4078H17.0293C15.2053 12.4078 13.6693 11.035 13.5157 9.28784C13.4293 8.28944 13.7941 7.29104 14.5237 6.58064C15.1381 5.94704 15.9925 5.59184 16.9141 5.59184H18.4021C18.6805 5.59184 18.9109 5.36144 18.8821 5.08304C18.6709 2.75024 17.1253 1.15664 14.8309 0.887844C14.6005 0.849444 14.3605 0.839844 14.1109 0.839844H5.4709C5.2021 0.839844 4.9429 0.859044 4.6933 0.897444C2.2453 1.20464 0.670898 3.02864 0.670898 5.63984V12.3598C0.670898 15.0094 2.8213 17.1598 5.4709 17.1598H14.1109C16.7989 17.1598 18.6517 15.4798 18.8821 12.9166C18.8875 12.8509 18.879 12.7848 18.8572 12.7225C18.8354 12.6603 18.8007 12.6033 18.7554 12.5553C18.7102 12.5073 18.6553 12.4694 18.5945 12.444C18.5336 12.4186 18.468 12.4063 18.4021 12.4078ZM11.2309 6.83984H5.4709C5.0773 6.83984 4.7509 6.51344 4.7509 6.11984C4.7509 5.72624 5.0773 5.39984 5.4709 5.39984H11.2309C11.6245 5.39984 11.9509 5.72624 11.9509 6.11984C11.9509 6.51344 11.6245 6.83984 11.2309 6.83984Z"
				fill="white"
			/>
		</svg>
	);
}
