import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IFarmPool {
	id: string;
	loyaltyAmount: string;
	loyaltyTokenSymbol: string;
	period: number;
	userId: string;
	claimAt?: string;
	startAt?: string;
}

interface FarmState {
	loading: boolean;
	farmPool: IFarmPool | null;
}

const initialState = { loading: false, farmPool: null } as FarmState;

const farmSlice = createSlice({
	name: 'farm',
	initialState,
	reducers: {
		runLoading(state) {
			state.loading = true;
			return state;
		},
		stopLoading(state) {
			state.loading = false;
			return state;
		},
		setFarmPool(state, action: PayloadAction<any>) {
			state.farmPool = action.payload;
			return state;
		},
	},
});

export const { runLoading, stopLoading, setFarmPool } = farmSlice.actions;
export default farmSlice.reducer;
