export const countDownScreenFormat = (
	totalSeconds: number,
	haveDay = false
) => {
	const days = Math.floor(totalSeconds / 86400);
	const hours = haveDay
		? Math.floor(totalSeconds / 3600) % 24
		: Math.floor(totalSeconds / 3600);
	const minutes = Math.floor(totalSeconds / 60) % 60;
	const seconds = totalSeconds % 60;
	return `${!haveDay ? '' : days > 9 ? `${days}:` : `0${days}:`}${
		hours > 9 ? hours : `0${hours}`
	}:${minutes > 9 ? minutes : `0${minutes}`}:${
		seconds > 9 ? seconds : `0${seconds}`
	}`;
};
