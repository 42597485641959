import React from 'react';

export default function TelegramIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M21.0001 4.78963L18.1531 19.5567C18.1531 19.5567 17.7552 20.5807 16.6601 20.0892L10.0609 14.8927C10.9479 14.0724 17.829 7.70775 18.1299 7.41912C18.5951 6.97244 18.3064 6.70659 17.7657 7.04441L7.60169 13.6851L3.68037 12.3269C3.68037 12.3269 3.06302 12.1016 3.00361 11.61C2.94384 11.1185 3.7004 10.8523 3.7004 10.8523L19.6863 4.40045C19.6863 4.40045 21.0001 3.80656 21.0001 4.78963Z"
				fill="white"
			/>
		</svg>
	);
}
