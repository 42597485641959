import React from 'react';

const CopyIC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={17}
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				d="M12 5V14C12 14.1326 11.9473 14.2598 11.8536 14.3536C11.7598 14.4473 11.6326 14.5 11.5 14.5H2.5C2.36739 14.5 2.24021 14.4473 2.14645 14.3536C2.05268 14.2598 2 14.1326 2 14V5C2 4.86739 2.05268 4.74021 2.14645 4.64645C2.24021 4.55268 2.36739 4.5 2.5 4.5H11.5C11.6326 4.5 11.7598 4.55268 11.8536 4.64645C11.9473 4.74021 12 4.86739 12 5ZM13.5 2.5H4.5C4.36739 2.5 4.24021 2.55268 4.14645 2.64645C4.05268 2.74021 4 2.86739 4 3C4 3.13261 4.05268 3.25979 4.14645 3.35355C4.24021 3.44732 4.36739 3.5 4.5 3.5H13V12C13 12.1326 13.0527 12.2598 13.1464 12.3536C13.2402 12.4473 13.3674 12.5 13.5 12.5C13.6326 12.5 13.7598 12.4473 13.8536 12.3536C13.9473 12.2598 14 12.1326 14 12V3C14 2.86739 13.9473 2.74021 13.8536 2.64645C13.7598 2.55268 13.6326 2.5 13.5 2.5Z"
				fill="white"
			/>
		</svg>
	);
};

export default CopyIC;
