import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { MISSION_CODE, MISSION_STATUS } from '../../constants/mission';
import { IMission } from '../../pages/mission';
import { MissionRequest } from '../../service/mission.request';
import { toast } from 'react-toastify';
import TaskItemComponent from './TaskItem';
import CheckCoinIc from '../Icons/CheckCoinIc';
import { formatNumber } from '../../utils/common';

const attendanceTasks = [
	{ id: 1, title: 'Day 1', bonus: 1000 },
	{ id: 2, title: 'Day 2', bonus: 2000 },
	{ id: 3, title: 'Day 3', bonus: 3000 },
	{ id: 4, title: 'Day 4', bonus: 4000 },
	{ id: 5, title: 'Day 5', bonus: 5000 },
	{ id: 6, title: 'Day 6', bonus: 6000 },
	{ id: 7, title: 'Day 7', bonus: 7000 },
];

interface IProps {
	list: IMission[];
	reFetchMissions: () => void;
}

export default function DailyTask({ list, reFetchMissions }: IProps) {
	const dailyAttendanceMission = list.find(
		(mission) => mission.code === MISSION_CODE.DAILY_ATTENDANCE
	);
	const dailyStoryMission = list.find(
		(mission) => mission.code === MISSION_CODE.DAILY_SHARING_STORY
	);

	const claimMission = async (mission: IMission) => {
		try {
			toast.loading('Claiming...');
			const req = new MissionRequest();
			const res = await req.claim(mission.id);
			toast.dismiss();
			toast.success('Claim successful');
			if (res.data) {
				reFetchMissions();
			}
		} catch (error: any) {
			console.log(error);
			toast.dismiss();
			const message = error?.response?.data?.message || '';
			toast.error(message);
		}
	};

	return (
		<Wrapper>
			<Title>Daily Tasks</Title>
			<BodyWrapper>
				<CardWrapper>
					<TaskList>
						{attendanceTasks.map((task, idx) => {
							const winStreak = Number(
								dailyAttendanceMission?.info.winStreak || 0
							);
							const isActive = task.id === winStreak;

							const done =
								(task.id === winStreak &&
									dailyAttendanceMission?.status === MISSION_STATUS.SUCCESS) ||
								task.id < winStreak;
							if (idx === attendanceTasks?.length - 1) {
								return (
									<TaskItem key={task.id}>
										<TaskDay7Wrapper active={isActive}>
											<Reward7DayWrapper>
												<Bonus>+{formatNumber(task.bonus)}</Bonus>
											</Reward7DayWrapper>
											<Bonus7dayTokenWrapper>
												{done ? (
													<CheckCoinIc />
												) : (
													<TokenImage
														src="/images/tokens/patc.svg"
														alt="patc"
													/>
												)}
											</Bonus7dayTokenWrapper>
										</TaskDay7Wrapper>
										<TaskTitle>{task.title}</TaskTitle>
									</TaskItem>
								);
							}
							return (
								<TaskItem key={task.id}>
									<TaskItemCard active={isActive}>
										<Bonus>+{formatNumber(task.bonus)}</Bonus>
										{done ? (
											<CheckCoinIc />
										) : (
											<TokenImage src="/images/tokens/patc.svg" alt="patc" />
										)}
									</TaskItemCard>
									<TaskTitle>{task.title}</TaskTitle>
								</TaskItem>
							);
						})}
					</TaskList>
					<GetPATCButton
						disabled={dailyAttendanceMission?.status === MISSION_STATUS.SUCCESS}
						onClick={() => {
							if (dailyAttendanceMission) {
								claimMission(dailyAttendanceMission);
							}
						}}
					>
						Get {dailyAttendanceMission?.loyaltyReward || 0}{' '}
						{dailyAttendanceMission?.loyaltyTokenSymbol || 'PATC'} now
					</GetPATCButton>
				</CardWrapper>
				{dailyStoryMission && (
					<TaskItemComponent
						task={dailyStoryMission}
						claimReward={claimMission}
						claimBtnText={'Share'}
					/>
				)}
			</BodyWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const BodyWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const Title = styled(Typography)`
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	border: 1px solid #273662;
	background: #0f2051;
	padding: 12px;
	gap: 12px;
	::-webkit-scrollbar {
		height: 3px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #191f2d;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #585858;
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #585858;
	}
`;

const TaskList = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	padding-bottom: 12px;
`;

const TaskItem = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	gap: 2px;
`;

const TaskTitle = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;

const TaskItemCard = styled.div<{ active?: boolean }>`
	box-sizing: border-box;
	min-width: 44px;
	min-height: 54px;
	/* height: 100%; */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	white-space: nowrap;
	overflow: hidden;
	gap: 2px;
	padding: 6px 4px;
	border-radius: 3px;
	border: ${(props) =>
		props.active ? '1px solid #00ABEB' : '1px solid rgba(255, 255, 255, 0.1)'};
	background: ${(props) =>
		props.active
			? 'linear-gradient(215deg, rgba(0, 171, 235, 0.20) 1.33%, rgba(0, 171, 235, 0.00) 98.67%)'
			: 'linear-gradient(209deg,rgba(255, 255, 255, 0.2) 1.64%,rgba(255, 255, 255, 0) 98.36%)'};
`;

const Bonus = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;
const TokenImage = styled.img`
	width: 24px;
	height: 24px;
`;

const TaskDay7Wrapper = styled.div<{ active?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	/* height: 100%; */
	border-radius: 3px;
	border: ${(props) =>
		props.active ? '1px solid #00ABEB' : '1px solid rgba(255, 255, 255, 0.1)'};
	background: #ffcb69;
	min-width: 44px;
	min-height: 54px;
	box-sizing: border-box;
`;

const Reward7DayWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url('/images/mission/day7-price.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	height: max-content;
	padding: 2px 0 6.16px;
`;

const Bonus7dayTokenWrapper = styled.div`
	background-image: url('/images/mission/light.png');
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	width: 42px;
	display: flex;
	justify-content: center;
	flex-grow: 1;
`;

const GetPATCButton = styled(Button)`
	height: max-content;
	padding: 12px 20px;
	span {
		text-align: center;

		/* App/Button/Small */
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 18px; /* 128.571% */
	}
`;
