import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
	content: string | ReactNode;
}

export default function PageTitle({ content }: IProps) {
	return <Title>{content}</Title>;
}

export const Title = styled(Typography)`
	text-align: center;

	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 28px; /* 127.273% */
`;
