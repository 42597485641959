import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DailyTask from '../components/Mission/DailyTask';
import MissionHeader from '../components/Mission/Header';
import MissionSummary from '../components/Mission/Summary';
import TaskList from '../components/Mission/TaskList';
import { MISSION_TYPE } from '../constants/mission';
import { MissionRequest } from '../service/mission.request';

export interface IMission {
	code: string;
	id: string;
	info?: any;
	loyaltyReward: string;
	loyaltyTokenSymbol: string;
	missionId: string;
	name: string;
	refId?: string | null;
	sourceType?: string | null;
	startAt?: string | null;
	status: string;
	type: string;
	userId: string;
	priority: number;
}

export interface ISummary {
	completedMission: number;
	loyaltyReceived: string;
	loyaltySymbol: string;
}

export default function MissionPage() {
	const [missions, setMissions] = useState<IMission[]>([]);
	const [summary, setSummary] = useState<ISummary | null>(null);
	const fetchMissions = async () => {
		try {
			const req = new MissionRequest();
			const res = await req.getMissions();

			if (res.data?.data) {
				setMissions(res.data?.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const fetchSummary = async () => {
		try {
			const req = new MissionRequest();
			const res = await req.getSummary();
			if (res.data?.data) {
				setSummary(res.data?.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const reFetchMissions = async () => {
		await fetchSummary();
		await fetchMissions();
	};

	useEffect(() => {
		fetchMissions();
		fetchSummary();
	}, []);

	return (
		<Wrapper>
			<MissionHeader />
			<MissionSummary summary={summary} />
			<DailyTask
				list={missions.filter((mission) => mission.type === MISSION_TYPE.DAILY)}
				reFetchMissions={reFetchMissions}
			/>
			<TaskList
				list={missions.filter((mission) => mission.type !== MISSION_TYPE.DAILY)}
				reFetchMissions={reFetchMissions}
			/>
		</Wrapper>
	);
}

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
`;
