import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
	borderBackground: string;
	bodyBackground: string;
	wFull?: boolean;
	isBtn?: boolean;
	onClick?: () => void;
	children: ReactNode;
}

export default function BlockUI({
	bodyBackground,
	borderBackground,
	children,
	wFull = false,
	isBtn = false,
	onClick,
}: IProps) {
	return (
		<Wrapper background={borderBackground} wFull={wFull} onClick={onClick}>
			<BodyWrapper background={bodyBackground} wFull={wFull} isBtn={isBtn}>
				{children}
			</BodyWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div<{ background: string; wFull: boolean }>`
	width: ${(props) => (props.wFull ? '100%' : 'max-content')};
	height: max-content;
	background: ${(props) => props.background};
	border-radius: 8px;
	padding: 1px;
`;
const BodyWrapper = styled.div<{
	background: string;
	wFull: boolean;
	isBtn: boolean;
}>`
	background: ${(props) => props.background};
	width: ${(props) => (props.wFull ? '100%' : 'max-content')};
	height: max-content;
	border-radius: 8px;
	cursor: ${(props) => (props.isBtn ? 'pointer' : 'default')};
`;
