import React from 'react';

export default function CoinMarketCapIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.1793 14.4133C18.8253 14.6295 18.4084 14.6563 18.0915 14.4836C17.689 14.2641 17.4677 13.7499 17.4677 13.0349V10.8955C17.4677 9.86243 17.0448 9.1273 16.3378 8.92881C15.1396 8.59162 14.2385 10.0078 13.8998 10.5397L11.7866 13.8494V9.80408C11.7628 8.87333 11.4499 8.31661 10.8558 8.14873C10.4626 8.03777 9.87444 8.08225 9.30306 8.9269L4.56869 16.2705C3.93487 15.1082 3.60446 13.8139 3.60617 12.5002C3.60617 8.07651 7.27603 4.47788 11.7866 4.47788C16.2972 4.47788 19.9665 8.07651 19.9665 12.5002C19.9665 12.5078 19.9685 12.5145 19.969 12.5217C19.969 12.5293 19.9675 12.536 19.968 12.5437C20.0106 13.4003 19.7234 14.0814 19.1793 14.4133ZM21.7841 12.5007V12.4782L21.7836 12.4562C21.7584 7.06876 17.284 2.69531 11.7861 2.69531C6.2739 2.69531 1.78857 7.09363 1.78857 12.5002C1.78857 17.9062 6.2739 22.305 11.7866 22.305C14.3162 22.305 16.7304 21.3762 18.5837 19.6902C18.952 19.3554 18.9738 18.7915 18.6322 18.4309C18.5518 18.3453 18.4545 18.276 18.346 18.2271C18.2375 18.1782 18.12 18.1508 18.0004 18.1463C17.8808 18.1418 17.7615 18.1605 17.6494 18.2012C17.5374 18.2418 17.4348 18.3037 17.3478 18.3831C15.8457 19.7572 13.8554 20.5228 11.7866 20.5225C9.37139 20.5225 7.19829 19.4898 5.69956 17.8522L9.969 11.2298V14.2827C9.969 15.7492 10.5577 16.2231 11.0513 16.3619C11.5455 16.5006 12.3005 16.4059 13.0932 15.1623L15.4416 11.4848C15.5169 11.3662 15.5862 11.2638 15.6496 11.1753V13.0349C15.6496 14.4057 16.218 15.5019 17.2087 16.0419C18.1019 16.5288 19.2248 16.4848 20.1398 15.9271C21.2489 15.2503 21.8465 14.0034 21.7841 12.5007Z"
				fill="white"
			/>
		</svg>
	);
}
