import React from 'react';

const ArrowRightIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={16}
			height={17}
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				d="M6.35403 3.14597L11.354 8.14597C11.4005 8.19241 11.4374 8.24755 11.4626 8.30825C11.4877 8.36895 11.5007 8.43401 11.5007 8.49972C11.5007 8.56543 11.4877 8.63049 11.4626 8.69119C11.4374 8.75189 11.4005 8.80704 11.354 8.85347L6.35403 13.8535C6.26021 13.9473 6.13296 14 6.00028 14C5.8676 14 5.74035 13.9473 5.64653 13.8535C5.55271 13.7597 5.5 13.6324 5.5 13.4997C5.5 13.367 5.55271 13.2398 5.64653 13.146L10.2934 8.49972L5.64653 3.85347C5.60007 3.80702 5.56322 3.75187 5.53808 3.69117C5.51294 3.63047 5.5 3.56542 5.5 3.49972C5.5 3.43402 5.51294 3.36897 5.53808 3.30827C5.56322 3.24758 5.60007 3.19243 5.64653 3.14597C5.69298 3.09952 5.74813 3.06267 5.80883 3.03753C5.86953 3.01238 5.93458 2.99944 6.00028 2.99944C6.06598 2.99944 6.13103 3.01238 6.19173 3.03753C6.25242 3.06267 6.30757 3.09952 6.35403 3.14597Z"
				fill="white"
			/>
		</svg>
	);
};

export default ArrowRightIc;
