import React from 'react';

export default function BinanceIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={21}
			height={21}
			viewBox="0 0 21 21"
			fill="none"
		>
			<g clipPath="url(#clip0_4703_18551)">
				<path
					d="M5.04421 10.5002L2.80136 12.7439L0.536865 10.5002L2.80053 8.23653L5.04421 10.5002ZM10.5273 5.01711L14.3903 8.88008L16.6548 6.61725L10.5273 0.509766L4.39983 6.63723L6.66433 8.90006L10.5273 5.01711ZM18.2541 8.23653L16.0104 10.5002L18.274 12.7639L20.5369 10.5002L18.2541 8.23653ZM10.5273 15.9833L6.66433 12.1003L4.39983 14.364L10.5273 20.4906L16.6548 14.3632L14.3903 12.1003L10.5273 15.9833ZM10.5273 12.7447L12.791 10.4802L10.5273 8.23736L8.26363 10.5002L10.5273 12.7439V12.7447Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0_4703_18551">
					<rect width={21} height={21} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
