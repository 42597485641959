import React from 'react';

export default function MissionActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M17.7331 10.8131L19.4931 14.3331C19.7331 14.8131 20.3731 15.2931 20.9065 15.3731L24.0931 15.9064C26.1331 16.2531 26.6131 17.7198 25.1465 19.1864L22.6665 21.6798C22.2531 22.0931 22.0131 22.9064 22.1465 23.4931L22.8531 26.5731C23.4131 28.9998 22.1198 29.9464 19.9731 28.6798L16.9865 26.9064C16.4398 26.5864 15.5598 26.5864 15.0131 26.9064L12.0131 28.6664C9.86647 29.9331 8.57314 28.9864 9.13314 26.5598L9.8398 23.4798C9.97314 22.9064 9.73313 22.0931 9.3198 21.6664L6.85314 19.1998C5.38647 17.7331 5.86647 16.2531 7.90647 15.9198L11.0931 15.3864C11.6265 15.2931 12.2665 14.8264 12.5065 14.3464L14.2665 10.8264C15.2131 8.90644 16.7865 8.90644 17.7331 10.8131Z"
				fill="white"
			/>
			<path
				d="M8 12.9993C7.45333 12.9993 7 12.546 7 11.9993V2.66602C7 2.11935 7.45333 1.66602 8 1.66602C8.54667 1.66602 9 2.11935 9 2.66602V11.9993C9 12.546 8.54667 12.9993 8 12.9993Z"
				fill="white"
			/>
			<path
				d="M24 12.9993C23.4533 12.9993 23 12.546 23 11.9993V2.66602C23 2.11935 23.4533 1.66602 24 1.66602C24.5467 1.66602 25 2.11935 25 2.66602V11.9993C25 12.546 24.5467 12.9993 24 12.9993Z"
				fill="white"
			/>
			<path
				d="M16 6.33268C15.4533 6.33268 15 5.87935 15 5.33268V2.66602C15 2.11935 15.4533 1.66602 16 1.66602C16.5467 1.66602 17 2.11935 17 2.66602V5.33268C17 5.87935 16.5467 6.33268 16 6.33268Z"
				fill="white"
			/>
		</svg>
	);
}
