import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LoyaltyWalletRequest } from '../../service/loyalty-wallet.request';

export const fetchBalance = createAsyncThunk(
	'loyalty-wallet/fetchBalance',
	async () => {
		const req = new LoyaltyWalletRequest();
		const res = await req.getMyWallet();
		return res.data?.data;
	}
);

interface LoyaltyWalletState {
	balance: string;
}

const initialState = { balance: '0' } as LoyaltyWalletState;

const loyaltyWalletSlice = createSlice({
	name: 'loyaltyWallet',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchBalance.fulfilled, (state, action) => {
			state.balance = action.payload?.totalBalance;
			return state;
		});
	},
});

export const {} = loyaltyWalletSlice.actions;
export default loyaltyWalletSlice.reducer;
