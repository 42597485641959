import BaseRequest from './BaseRequest';

class ReferralRequest extends BaseRequest {
	async getInviters(params?: Params) {
		return this.get('/referral/inviters', params);
	}
	async createReferralInit() {
		return this.post('/referral/init');
	}
	async getProfile() {
		return this.get('/referral/profile');
	}
	async getSummary() {
		return this.get('/referral/summary');
	}
	async payJoinWithCode() {
		return this.post('/referral/pay-join-with-code');
	}
}

export default ReferralRequest;
