import React from 'react';
import styled from 'styled-components';
import PageTitle from '../UI/PageTitle';
import PageDescription from '../UI/PageDescription';

export default function MissionHeader() {
	return (
		<Wrapper>
			<PageTitle content="Mission" />
			<PageDescription content="Discover the powerful features that make our platform stand out." />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
