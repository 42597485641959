import { useEffect } from 'react';
import { ACCESS_TOKEN } from '../../constants';
import { fetchBalance } from '../../redux/reducers/loyalty-wallet.slice';
import { updateUser } from '../../redux/reducers/user.slice';
import { useAppDispatch } from '../../redux/store';
import authRequest from '../../service/authRequest';
const TelegramLogin = () => {
	const dispatch = useAppDispatch();
	const signIn = async (initData: string) => {
		try {
			const res = await authRequest.signIn({
				initData,
				// 'user=%7B%22id%22%3A6153313086%2C%22first_name%22%3A%22Arthur%22%2C%22last_name%22%3A%22Tran%22%2C%22username%22%3A%22arthurtran12%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=2195370664920507105&chat_type=sender&auth_date=1725588969&hash=56dd7ca7c924b11696387fd5a8ebc35cff0eef723cb15fe8cecb2bba67954b47',
			});
			localStorage.setItem(ACCESS_TOKEN, res.data.data.accessToken);
			await dispatch(fetchBalance());
			await dispatch(updateUser({ ...res.data?.data }));
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		const tg = window.Telegram.WebApp;
		signIn(tg.initData);
	}, []);

	return <></>;
};

export default TelegramLogin;
