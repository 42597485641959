import React from 'react';

export default function RankingActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={28}
			height={24}
			viewBox="0 0 28 24"
			fill="none"
		>
			<path
				d="M25.1331 5.3607L19.7998 9.17404C19.0931 9.6807 18.0798 9.37404 17.7731 8.5607L15.2531 1.8407C14.8265 0.680703 13.1865 0.680703 12.7598 1.8407L10.2265 8.54737C9.9198 9.37404 8.9198 9.6807 8.21314 9.1607L2.8798 5.34737C1.81314 4.6007 0.399804 5.65404 0.839804 6.89404L6.38647 22.4274C6.57314 22.9607 7.0798 23.3074 7.6398 23.3074H20.3465C20.9065 23.3074 21.4131 22.9474 21.5998 22.4274L27.1465 6.89404C27.5998 5.65404 26.1865 4.6007 25.1331 5.3607ZM17.3331 17.6674H10.6665C10.1198 17.6674 9.66647 17.214 9.66647 16.6674C9.66647 16.1207 10.1198 15.6674 10.6665 15.6674H17.3331C17.8798 15.6674 18.3331 16.1207 18.3331 16.6674C18.3331 17.214 17.8798 17.6674 17.3331 17.6674Z"
				fill="white"
			/>
		</svg>
	);
}
