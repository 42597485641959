import {
	StyledAccountName,
	StyledAccountRanking,
	StyledCircleRanking,
	StyledItemRanking,
	StyledWrapperListRanking,
} from './index.styled';

const ListRanking = ({ list }: { list: Inviter[] }) => {
	return (
		<StyledWrapperListRanking>
			{list.map((item, index) => (
				<StyledItemRanking key={item.id}>
					<StyledCircleRanking>
						{index + 4}
						<sup>th</sup>
					</StyledCircleRanking>
					<StyledAccountRanking>
						<StyledAccountName>{item.username}</StyledAccountName>
						<div>
							{item.totalFriends}{' '}
							{parseInt(item.totalFriends) > 1 ? 'Friends' : 'Friend'}
						</div>
					</StyledAccountRanking>
				</StyledItemRanking>
			))}
		</StyledWrapperListRanking>
	);
};

export default ListRanking;
