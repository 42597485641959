import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserRequest } from '../../service/user.request';

export const fetchUserProfile = createAsyncThunk(
	'user/fetchProfile',
	async () => {
		const req = new UserRequest();
		const res = await req.getMe();
		return res.data?.data;
	}
);

interface UserState {
	id: string;
	username: string;
	telegramId: string;
	accessToken: string;
	avatar?: string;
}

const initialState = {
	id: '',
	username: '',
	telegramId: '',
	accessToken: '',
} as UserState;

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		updateUser(state, action: PayloadAction<UserState>) {
			state = { ...action.payload };
			return state;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
			if (action.payload) {
				return { ...state, ...action.payload };
			}
		});
	},
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
