import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Layer from '../components/Home/Layer';
import TelegramLogin from '../components/Home/TelegramLogin';
import NotificationPopup from '../components/Notification/NotificationPopup';
import Tabbar from '../components/UI/Tabbar';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { ROUTES } from '../routes';
import { TelegramRequest } from '../service/telegram.request';
import LoadingLayout from './loading.layout';
import ReferralRequest from '../service/referral.request';
import { fetchReferralProfilce } from '../redux/reducers/referral.slice';
import { NotificationRequest } from '../service/notification.request';
import { fetchBalance } from '../redux/reducers/loyalty-wallet.slice';
import BigNumber from 'bignumber.js';

interface INotification {
	userId: string;
	info: any;
	isRead: boolean;
	title: string;
	content: string;
}

export default function HomeLayout() {
	const location = useLocation();
	const user = useAppSelector((state: RootState) => state.user);
	const dispatch = useAppDispatch();
	const [notifications, setNotifications] = useState<INotification[]>([]);

	const payTelegramLifeExpectancy = async () => {
		try {
			const req = new TelegramRequest();
			const res = await req.payLifeExpectancy();
			fetchNotifications();
			dispatch(fetchBalance());
		} catch (error) {
			console.log('payTelegramLifeExpectancy error', error);
		}
	};

	const payRewardJoinWithCode = async () => {
		try {
			const req = new ReferralRequest();
			const res = await req.payJoinWithCode();
			fetchNotifications();
			dispatch(fetchBalance());
		} catch (error) {
			console.log('payRewardJoinWithCode error', error);
		}
	};

	const fetchNotifications = async () => {
		try {
			const req = new NotificationRequest();
			const res = await req.getListUnRead();
			const data: INotification[] = res.data?.data;
			console.log('data notification', data);
			const newNotifications: INotification[] = [];
			for (let i = 0; i < data?.length; i++) {
				const newNotificationIdx = newNotifications.findIndex(
					(newNoti) => newNoti?.info?.type === data[i]?.info?.type
				);
				if (newNotificationIdx < 0) {
					newNotifications.push(data[i]);
					continue;
				}
				if (
					!newNotifications[newNotificationIdx].info?.reward ||
					!data[i].info?.reward
				) {
					continue;
				}
				newNotifications[newNotificationIdx].info = {
					...newNotifications[newNotificationIdx].info,
					reward: new BigNumber(
						newNotifications[newNotificationIdx].info?.reward
					)
						.plus(data[i]?.info?.reward)
						.toString(),
				};
			}
			console.log('newNotifications', newNotifications);
			setNotifications(
				newNotifications.map((noti) => ({
					...noti,
					content: noti.content.replace('[Z]', noti.info.reward || ''),
				}))
			);
		} catch (error) {}
	};

	useEffect(() => {
		if (user.id) {
			fetchNotifications();
			dispatch(fetchReferralProfilce());
			payTelegramLifeExpectancy();
			payRewardJoinWithCode();
		}
	}, [user.id]);

	if (!user.id)
		return (
			<>
				<LoadingLayout />
				<TelegramLogin />
			</>
		);

	return (
		<Wrapper>
			<Layer />
			<Body isPadding={location.pathname !== ROUTES.PACKAGES}>
				<Outlet />
			</Body>
			{location.pathname === ROUTES.PACKAGES ? null : <Tabbar />}
			<NotificationPopup
				notifications={notifications}
				reloadNotification={fetchNotifications}
			/>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	/* min-height: calc(100vh - 110px);
	padding-bottom: 110px;
	height: 100%; */
	height: 100dvh;
	max-width: 500px;
	margin: 0 auto;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	background: #000;
	position: relative;
`;
const Body = styled.div<{ isPadding: boolean }>`
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	padding-bottom: ${(props) => (props.isPadding ? '110px' : '0px')};
	flex-grow: 1;
	overflow-y: auto;
`;
