import React from 'react';

const WalletLargeIc = () => {
	return (
		<svg
			width={122}
			height={122}
			viewBox="0 0 122 122"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x={1}
				y={1}
				width={120}
				height={120}
				rx={60}
				fill="url(#paint0_linear_4703_23966)"
				fillOpacity="0.2"
			/>
			<rect
				x={1}
				y={1}
				width={120}
				height={120}
				rx={60}
				stroke="#00ABEB"
				strokeWidth={2}
				strokeLinecap="round"
				strokeDasharray="5 5"
			/>
			<path
				d="M93.9032 57.7054V64.2974C93.9032 66.0574 92.4952 67.4974 90.7032 67.5614H84.4312C80.9752 67.5614 77.8072 65.0334 77.5192 61.5774C77.3272 59.5614 78.0952 57.6734 79.4392 56.3614C80.6232 55.1454 82.2552 54.4414 84.0472 54.4414H90.7032C92.4952 54.5054 93.9032 55.9454 93.9032 57.7054Z"
				fill="white"
			/>
			<path
				d="M89.0072 72.3608H84.4312C78.3512 72.3608 73.2312 67.7848 72.7192 61.9608C72.4312 58.6328 73.6472 55.3048 76.0792 52.9368C78.1272 50.8248 80.9752 49.6408 84.0472 49.6408H89.0072C89.9352 49.6408 90.7032 48.8728 90.6072 47.9448C89.9032 40.1688 84.7512 34.8568 77.1032 33.9608C76.3352 33.8328 75.5352 33.8008 74.7032 33.8008H45.9032C45.0072 33.8008 44.1432 33.8648 43.3112 33.9928C35.1512 35.0168 29.9032 41.0968 29.9032 49.8008V72.2008C29.9032 81.0328 37.0712 88.2008 45.9032 88.2008H74.7032C83.6632 88.2008 89.8392 82.6008 90.6072 74.0568C90.6252 73.8376 90.5969 73.6172 90.5242 73.4097C90.4515 73.2022 90.3359 73.0123 90.185 72.8523C90.0341 72.6924 89.8513 72.566 89.6484 72.4813C89.4455 72.3966 89.227 72.3556 89.0072 72.3608ZM65.1032 53.8008H45.9032C44.5912 53.8008 43.5032 52.7128 43.5032 51.4008C43.5032 50.0888 44.5912 49.0008 45.9032 49.0008H65.1032C66.4152 49.0008 67.5032 50.0888 67.5032 51.4008C67.5032 52.7128 66.4152 53.8008 65.1032 53.8008Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_4703_23966"
					x1="116.588"
					y1="0.999999"
					x2="1.00001"
					y2={121}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset={1} stopColor="white" stopOpacity={0} />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default WalletLargeIc;
