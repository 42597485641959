import React from 'react';

const CheckCoinIc = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<circle cx={12} cy={12} r={4} fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.1532 6.66797C11.0983 6.66797 10.0672 6.98076 9.19012 7.5668C8.31306 8.15283 7.62947 8.98578 7.2258 9.96032C6.82214 10.9349 6.71652 12.0072 6.92231 13.0418C7.12809 14.0763 7.63604 15.0267 8.38192 15.7725C9.1278 16.5184 10.0781 17.0264 11.1127 17.2322C12.1472 17.4379 13.2196 17.3323 14.1941 16.9287C15.1687 16.525 16.0016 15.8414 16.5877 14.9643C17.1737 14.0873 17.4865 13.0561 17.4865 12.0013C17.4865 10.5868 16.9246 9.23026 15.9244 8.23007C14.9242 7.22987 13.5676 6.66797 12.1532 6.66797ZM14.5932 11.5413L12.2132 13.9213C12.0698 14.0618 11.8772 14.1405 11.6765 14.1405C11.4758 14.1405 11.2831 14.0618 11.1398 13.9213L9.70649 12.4946C9.5656 12.3502 9.48673 12.1564 9.48673 11.9546C9.48673 11.7529 9.5656 11.5591 9.70649 11.4146C9.77716 11.3433 9.86127 11.2866 9.95395 11.248C10.0466 11.2093 10.1461 11.1894 10.2465 11.1894C10.3469 11.1894 10.4463 11.2093 10.539 11.248C10.6317 11.2866 10.7158 11.3433 10.7865 11.4146L11.6732 12.308L13.5198 10.4613C13.663 10.319 13.8569 10.2394 14.0589 10.24C14.2608 10.2406 14.4542 10.3214 14.5965 10.4646C14.7388 10.6079 14.8184 10.8017 14.8178 11.0037C14.8172 11.2056 14.7364 11.399 14.5932 11.5413Z"
				fill="#0CD92A"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20.6665 8.66771C20.5599 8.50867 20.4873 8.32939 20.4531 8.14104L19.8598 5.22104C19.7991 4.92807 19.6536 4.65941 19.4414 4.44843C19.2293 4.23746 18.9598 4.09347 18.6665 4.03438L15.8065 3.44104C15.6153 3.40206 15.4339 3.32497 15.2731 3.21438L12.8465 1.59438C12.5967 1.4247 12.3017 1.33398 11.9998 1.33398C11.6979 1.33398 11.4029 1.4247 11.1531 1.59438L8.72647 3.21438C8.56399 3.32629 8.38015 3.40346 8.18647 3.44104L5.33314 4.03438C5.04139 4.09628 4.77416 4.24229 4.56446 4.45436C4.35475 4.66643 4.21176 4.93528 4.15314 5.22771L3.5598 8.14771C3.51477 8.33281 3.43808 8.50873 3.33314 8.66771L1.7198 11.1677C1.55989 11.4162 1.47485 11.7055 1.47485 12.001C1.47485 12.2966 1.55989 12.5859 1.7198 12.8344L3.33314 15.3344C3.43411 15.4963 3.50629 15.6745 3.54647 15.861L4.1398 18.781C4.19461 19.0801 4.33747 19.356 4.55008 19.5734C4.76269 19.7907 5.03535 19.9396 5.33314 20.001L8.18647 20.5944C8.38015 20.632 8.56399 20.7091 8.72647 20.821L11.1531 22.441C11.4029 22.6107 11.6979 22.7014 11.9998 22.7014C12.3017 22.7014 12.5967 22.6107 12.8465 22.441L15.2731 20.821C15.4339 20.7105 15.6153 20.6334 15.8065 20.5944L18.6665 20.001C18.9582 19.9391 19.2254 19.7931 19.4351 19.5811C19.6449 19.369 19.7878 19.1001 19.8465 18.8077L20.4398 15.8877C20.4744 15.689 20.5518 15.5002 20.6665 15.3344L22.2931 12.8344C22.4527 12.5882 22.5376 12.3011 22.5376 12.0077C22.5376 11.7143 22.4527 11.4272 22.2931 11.181L20.6665 8.66771ZM12.1531 18.8544C10.7977 18.8544 9.47265 18.4524 8.34563 17.6994C7.2186 16.9463 6.34019 15.876 5.82148 14.6237C5.30277 13.3714 5.16705 11.9934 5.43149 10.664C5.69593 9.33461 6.34864 8.11346 7.3071 7.15501C8.26555 6.19655 9.4867 5.54383 10.8161 5.27939C12.1455 5.01496 13.5235 5.15068 14.7758 5.66939C16.0281 6.1881 17.0984 7.06651 17.8515 8.19354C18.6045 9.32056 19.0065 10.6456 19.0065 12.001C19.0029 13.8176 18.2798 15.5587 16.9953 16.8432C15.7108 18.1277 13.9697 18.8509 12.1531 18.8544Z"
				fill="#1EE66A"
			/>
		</svg>
	);
};

export default CheckCoinIc;
