import React from 'react';

export default function WalletActiveIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={32}
			height={32}
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M19.8002 5.26576V10.3324H17.8002V5.26576C17.8002 4.90576 17.4802 4.73242 17.2668 4.73242C17.2002 4.73242 17.1335 4.74576 17.0668 4.77242L6.4935 8.75909C5.78683 9.02576 5.3335 9.69242 5.3335 10.4524V11.3458C4.12016 12.2524 3.3335 13.7058 3.3335 15.3458V10.4524C3.3335 8.86576 4.30683 7.45242 5.78683 6.89242L16.3735 2.89242C16.6668 2.78576 16.9735 2.73242 17.2668 2.73242C18.6002 2.73242 19.8002 3.81242 19.8002 5.26576Z"
				fill="white"
			/>
			<path
				d="M28.6667 19.3327V20.666C28.6667 21.026 28.3867 21.3193 28.0134 21.3327H26.0667C25.3601 21.3327 24.7201 20.8127 24.6667 20.1193C24.6267 19.706 24.7867 19.3193 25.0534 19.0527C25.2934 18.7993 25.6267 18.666 25.9867 18.666H28.0001C28.3867 18.6793 28.6667 18.9727 28.6667 19.3327Z"
				fill="white"
			/>
			<path
				d="M25.9735 17.2673H27.3335C28.0668 17.2673 28.6668 16.6673 28.6668 15.934V15.3473C28.6668 12.5873 26.4135 10.334 23.6535 10.334H8.34683C7.2135 10.334 6.1735 10.7073 5.3335 11.3473C4.12016 12.254 3.3335 13.7073 3.3335 15.3473V24.3207C3.3335 27.0807 5.58683 29.334 8.34683 29.334H23.6535C26.4135 29.334 28.6668 27.0807 28.6668 24.3207V24.0673C28.6668 23.334 28.0668 22.734 27.3335 22.734H26.1735C24.8935 22.734 23.6668 21.9473 23.3335 20.7073C23.0535 19.694 23.3868 18.7207 24.0535 18.0673C24.5468 17.5607 25.2268 17.2673 25.9735 17.2673ZM18.6668 17.0007H9.3335C8.78683 17.0007 8.3335 16.5473 8.3335 16.0007C8.3335 15.454 8.78683 15.0007 9.3335 15.0007H18.6668C19.2135 15.0007 19.6668 15.454 19.6668 16.0007C19.6668 16.5473 19.2135 17.0007 18.6668 17.0007Z"
				fill="white"
			/>
		</svg>
	);
}
