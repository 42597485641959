import React from 'react';

export default function WebsiteIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.83333 8C3.41307 6.6772 4.27145 5.49509 5.34985 4.53438C6.42825 3.57368 7.70128 2.85702 9.082 2.43333C8.94067 2.62467 8.80333 2.83067 8.672 3.05067C7.93 4.29533 7.34067 6.00867 6.998 8H2.83333ZM14.918 2.43333C16.2987 2.85702 17.5717 3.57368 18.6502 4.53438C19.7286 5.49509 20.5869 6.6772 21.1667 8H17.002C16.6593 6.00867 16.07 4.29533 15.328 3.05067C15.1967 2.83067 15.0593 2.62467 14.918 2.43333ZM21.1667 16C20.5869 17.3228 19.7286 18.5049 18.6502 19.4656C17.5717 20.4263 16.2987 21.143 14.918 21.5667C15.0593 21.3753 15.1967 21.1693 15.328 20.9493C16.07 19.7047 16.6593 17.9913 17.002 16H21.1667ZM9.082 21.5667C7.70128 21.143 6.42825 20.4263 5.34985 19.4656C4.27145 18.5049 3.41307 17.3228 2.83333 16H6.998C7.34067 17.9913 7.93 19.7047 8.672 20.9493C8.80333 21.1693 8.94067 21.3753 9.082 21.5667ZM12 2C12.8347 2 13.5567 2.68533 14.1827 3.734C14.8293 4.818 15.3387 6.29067 15.6513 8H8.34867C8.66133 6.29067 9.17067 4.818 9.81733 3.734C10.4433 2.68533 11.1653 2 12 2ZM12 22C11.1653 22 10.4433 21.3147 9.81733 20.266C9.17067 19.182 8.66133 17.7093 8.34867 16H15.6513C15.3387 17.7093 14.8293 19.182 14.1827 20.266C13.5567 21.3147 12.8347 22 12 22ZM21.64 9.33333C21.8747 10.1827 22 11.0767 22 12C22 12.9233 21.8747 13.8173 21.64 14.6667H17.1893C17.2833 13.8127 17.3333 12.9193 17.3333 12C17.3333 11.0807 17.2833 10.1873 17.1893 9.33333H21.64ZM2.36 14.6667C2.12533 13.8173 2 12.9233 2 12C2 11.0767 2.12533 10.1827 2.36 9.33333H6.81067C6.71667 10.1873 6.66667 11.0807 6.66667 12C6.66667 12.9193 6.71667 13.8127 6.81067 14.6667H2.36ZM15.8493 14.6667H8.15067C8.052 13.816 8 12.922 8 12C8 11.078 8.052 10.184 8.15067 9.33333H15.8493C15.948 10.184 16 11.078 16 12C16 12.922 15.948 13.816 15.8493 14.6667Z"
				fill="white"
			/>
		</svg>
	);
}
