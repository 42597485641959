import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ReferralRequest from '../../service/referral.request';

export interface Profile {
	id: string;
	userId: string;
	code: string;
	referer: string;
	networks: string[];
	createdAt: string;
	updatedAt: string;
}

export const fetchReferralProfilce = createAsyncThunk(
	'referral/fetchReferralProfilce',
	async () => {
		const req = new ReferralRequest();
		const res = await req.getProfile();
		return res.data?.data;
	}
);

interface ReferralState {
	profile: Profile | null;
}

const initialState = { profile: null } as ReferralState;

const referralSlice = createSlice({
	name: 'referral',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchReferralProfilce.fulfilled, (state, action) => {
			if (action.payload) {
				state.profile = action.payload;
			}
		});
	},
});

export const {} = referralSlice.actions;
export default referralSlice.reducer;
