import React from 'react';

export default function WalletIc() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M2.5 12.4093V7.83937C2.5 6.64937 3.23 5.58933 4.34 5.16933L12.28 2.16933C13.52 1.69933 14.85 2.61936 14.85 3.94936V7.74934"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.5598 13.9692V16.0292C22.5598 16.5792 22.1198 17.0292 21.5598 17.0492H19.5998C18.5198 17.0492 17.5298 16.2592 17.4398 15.1792C17.3798 14.5492 17.6198 13.9592 18.0398 13.5492C18.4098 13.1692 18.9198 12.9492 19.4798 12.9492H21.5598C22.1198 12.9692 22.5598 13.4192 22.5598 13.9692Z"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				opacity="0.4"
				d="M7 12H14"
				stroke="white"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
