import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PackageHeader from '../components/Packages/Header';
import PackageItem from '../components/Packages/Item';
import { useNavigate } from 'react-router-dom';
import UpgradeBoosterPopup, {
	IPackage,
} from '../components/Packages/UpgradeBoosterPopup';
import { BoosterRequest } from '../service/booster.request';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';
import { fetchMyBoosters } from '../redux/reducers/booster.slice';
import { toast } from 'react-toastify';
import { fetchBalance } from '../redux/reducers/loyalty-wallet.slice';
import BigNumber from 'bignumber.js';

export default function PackagesPage() {
	const navigate = useNavigate();
	const [packageList, setPackageList] = useState<IPackage[]>([]);
	const [packageSelected, setPackageSelected] = useState<string | null>(null);
	const { myBoosters } = useAppSelector((state: RootState) => state.booster);
	const { balance } = useAppSelector((state: RootState) => state.loyaltyWallet);
	const dispatch = useAppDispatch();

	const fetchPackages = async () => {
		try {
			const boosterReq = new BoosterRequest();
			const res = await boosterReq.getPackages();
			if (res.data?.data) {
				setPackageList(res.data?.data);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const buyPackage = async (packageId: string) => {
		try {
			toast.loading('Processing...');
			const boosterReq = new BoosterRequest();
			const res = await boosterReq.buy(packageId);
			toast.dismiss();
			if (res.data?.data) {
				toast.success('Upgrade successful');
				dispatch(fetchMyBoosters());
				dispatch(fetchBalance());
			}
		} catch (error: any) {
			console.log(error);
			toast.dismiss();
			const message = error?.response?.data?.message || '';
			toast.error(message || 'Upgrade failed');
		}
	};

	const handleSelectPackage = async (packageItem: IPackage) => {
		if (new BigNumber(packageItem.price).gt(balance)) {
			toast.error('Not enough balance');
			return;
		}
		setPackageSelected(packageItem.id);
	};

	useEffect(() => {
		fetchPackages();
		if (!myBoosters?.length) {
			dispatch(fetchMyBoosters());
		}
	}, []);

	const packageSelectedInfo = packageList.find(
		(packageItem) => packageItem.id === packageSelected
	);

	return (
		<>
			<Wrapper>
				<PackageHeader
					onBack={() => {
						navigate(-1);
					}}
				/>
				<ListWrapper>
					{packageList
						.sort(
							(prevPackage, nextPackage) =>
								Number(prevPackage.price) - Number(nextPackage.price)
						)
						.map((packageItem) => (
							<PackageItem
								key={packageItem.id}
								info={packageItem}
								onUpgrade={(id) => {
									handleSelectPackage(packageItem);
								}}
								isUpgraded={
									!!myBoosters.filter(
										(myBooster) => myBooster.packageId === packageItem.id
									)?.length
								}
							/>
						))}
				</ListWrapper>
			</Wrapper>

			<UpgradeBoosterPopup
				open={!!packageSelectedInfo}
				packageInfo={packageSelectedInfo}
				onClose={() => {
					setPackageSelected(null);
				}}
				onBuy={(id: string) => {
					buyPackage(id);
					setPackageSelected(null);
				}}
			/>
		</>
	);
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 0;
	left: 0;
	/* width: 100%; */
	/* height: 100%; */
	padding: 16px 16px 30px;
`;
const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 30px 0;
`;
