import React, { useEffect, useRef, useState } from 'react';
import PopupUI from '../UI/Popup/PopupUI';
import styled from 'styled-components';
import { Button, Carousel, Typography } from 'antd';
import { NotificationRequest } from '../../service/notification.request';
import uploadRequest from '../../service/upload.request';
import html2canvas from 'html2canvas';
import { RootState, useAppSelector } from '../../redux/store';
import { NotificationType } from '../../constants';

interface IProps {
	notifications: any[];
	reloadNotification: () => void;
}

export default function NotificationPopup({
	notifications,
	reloadNotification,
}: IProps) {
	const [open, setOpen] = useState(false);
	const [loadingShare, setLoadingShare] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);
	const referral = useAppSelector((state: RootState) => state.referral);
	const carouselRef = useRef<any>();
	const notificationRef = useRef<HTMLDivElement[]>([]);

	const exportFileImage = async (el: HTMLDivElement) => {
		const clonedNode = el.cloneNode(true) as HTMLElement;
		clonedNode.style.width = '100vw';
		clonedNode.style.height = '100vh';
		clonedNode.style.display = 'flex';
		clonedNode.style.flexDirection = 'column';
		clonedNode.style.justifyContent = 'center';
		clonedNode.style.padding = `18px`;

		clonedNode.style.position = 'absolute';
		clonedNode.style.left = '-9999px';
		document.body.appendChild(clonedNode);

		try {
			const canvas = await html2canvas(clonedNode, {
				backgroundColor: null,
				useCORS: true,
			});
			const dataUrl = canvas.toDataURL('image/png');

			const convertToFile = fetch(dataUrl)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], `File name`, {
						type: 'image/png',
					});
					return file;
				});
			return convertToFile;
		} catch (error) {
			return null;
		} finally {
			document.body.removeChild(clonedNode);
		}
	};

	const readNotification = async (id: string) => {
		try {
			const req = new NotificationRequest();
			// const res =
			await req.read(id);
			// setOpen(false);
		} catch (error) {}
	};

	const readNotificationAll = async () => {
		try {
			const req = new NotificationRequest();
			// const res =
			await req.readAll();
			// setOpen(false);
		} catch (error) {}
	};

	const onShare = async () => {
		setLoadingShare(true);
		try {
			const file = await exportFileImage(notificationRef.current[currentSlide]);
			const urlFiles = file && (await uploadRequest.upload(file));
			if (window.Telegram && window.Telegram.WebApp) {
				const tg = window.Telegram.WebApp;
				tg.shareToStory(urlFiles?.data.data.url, {
					text: `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${referral.profile?.code} #PATC #PADTON #PADTONCommunity`,
					widget_link: {
						name: 'link',
						url: `https://t.me/${process.env.REACT_APP_BOT_USERNAME}/?start=r_${referral.profile?.code}`,
					},
				});
			}

			if (notifications?.length) {
				const currentNoti = notifications[currentSlide];
				await readNotification(currentNoti.id);
				reloadNotification();
				if (currentSlide >= notifications?.length - 1) {
					// setOpen(false);
					return;
				}
				carouselRef.current?.next();
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingShare(false);
		}
	};

	const onChangeCarousel = (currentSlide: number) => {
		setCurrentSlide(currentSlide);
	};

	useEffect(() => {
		if (notifications?.length) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [notifications]);

	return (
		<>
			<PopupUI
				open={open}
				onClose={() => {
					setOpen(false);
					readNotificationAll();
				}}
				closeable={false}
				footer={
					notifications[currentSlide] &&
					notifications[currentSlide]?.info &&
					(notifications[currentSlide].info.type ===
						NotificationType.TELEGRAM_AGE ||
						notifications[currentSlide].info.type ===
							NotificationType.JOIN_WITH_CODE) && (
						<ShareButton onClick={onShare} loading={loadingShare}>
							<TextButton>Share story</TextButton>
						</ShareButton>
					)
				}
				title="Congratulation"
			>
				<CarouselUI
					draggable
					infinite={false}
					initialSlide={currentSlide}
					afterChange={onChangeCarousel}
					ref={carouselRef}
				>
					{notifications &&
						notifications.map((noti, idx) => (
							<Wrapper
								key={noti.id}
								ref={(el) => {
									if (el) {
										notificationRef.current[idx] = el;
									}
								}}
							>
								{noti?.info && (
									<>
										{noti.info.type === NotificationType.TELEGRAM_AGE && (
											<CountContainer>
												<Image
													src={'/images/home/bg-count-time.png'}
													alt="notification image"
												/>
												<CountContent>
													<CountValue>{noti.info.number}</CountValue>
													<CountLabel>
														{`${noti.info.unit}${
															parseInt(noti.info.number) > 1 ? 's' : ''
														}`}{' '}
														Ago
													</CountLabel>
												</CountContent>
											</CountContainer>
										)}
										{noti.info.type === NotificationType.JOIN_WITH_CODE && (
											<Image
												src={'/images/home/invited.png'}
												alt="notification image"
											/>
										)}
										{noti.info.type === NotificationType.INVITE_FRIEND && (
											<Image
												src={'/images/home/has-invited.png'}
												alt="notification image"
											/>
										)}
										{(noti.info.type === NotificationType.REFERRAL_COMMISSION ||
											noti.info.type === NotificationType.BOOSTER_BONUS) && (
											<Image
												src={'/images/home/referral-commission.png'}
												alt="notification image"
											/>
										)}
									</>
								)}
								<Content>{noti.content}</Content>
								<RewardWrapper>
									<TokenImage src="/images/tokens/patc.svg" alt="token" />
									{noti?.info && (
										<Reward>
											{noti.info.reward} {noti.info.symbol}
										</Reward>
									)}
								</RewardWrapper>
							</Wrapper>
						))}
				</CarouselUI>
			</PopupUI>
		</>
	);
}

export const ShareButton = styled(Button)`
	padding: 12px 20px;
	height: max-content;
	width: 100%;
`;

const TextButton = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 21px; /* 131.25% */
`;

const CountContainer = styled.div`
	position: relative;
`;

const CountContent = styled.div`
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const CountValue = styled(Typography)`
	font-size: 120px;
	font-weight: 700;
	color: #fff;
	margin-top: -20px;
`;

const CountLabel = styled(Typography)`
	font-size: 34px;
	font-weight: 600;
	color: #fff;
	margin-top: -40px;
	text-transform: capitalize;
`;

const Wrapper = styled.div`
	font-family: Poppins;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	width: 100%;
	flex-shrink: 0;
`;

const Image = styled.img``;
const Content = styled(Typography)`
	color: var(--Colors-white-white, #fff);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 18px; /* 128.571% */
`;
const RewardWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`;
const Reward = styled(Typography)`
	color: #f5d245;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 34px; /* 154.545% */
`;
const TokenImage = styled.img`
	/* width: 32px;
	height: 32px; */
	object-fit: contain;
	background: transparent;
`;

const CarouselUI = styled(Carousel)`
	margin-bottom: 20px;
`;
