import React from 'react';

const CloseCircleBlackIc = ({ onClick }: { onClick: any }) => {
	return (
		<svg
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
			width={24}
			height={24}
			viewBox="0 0 24 24"
			fill="none"
		>
			<rect width={24} height={24} rx={12} fill="#0B0E1F" />
			<path
				d="M16 8L8 16"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 8L16 16"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CloseCircleBlackIc;
