import { Typography } from 'antd';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface IProps {
	content: string | ReactNode;
}

export default function PageDescription({ content }: IProps) {
	return <Text>{content}</Text>;
}

const Text = styled(Typography)`
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 162.5% */
`;
