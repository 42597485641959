import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BoosterRequest } from '../../service/booster.request';

export const fetchMyBoosters = createAsyncThunk(
	'booster/fetchMyBoosters',
	async () => {
		const req = new BoosterRequest();
		const res = await req.getMyBoosters();
		return res.data?.data;
	}
);

interface BoosterState {
	myBoosters: any[];
}

const initialState = { myBoosters: [] } as BoosterState;

const boosterSlice = createSlice({
	name: 'booster',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMyBoosters.fulfilled, (state, action) => {
			if (action.payload) {
				state.myBoosters = action.payload;
			}
		});
	},
});

export const {} = boosterSlice.actions;
export default boosterSlice.reducer;
